import React, { Component } from "react";
import Card from "./io.enthusiast.businesscard/Card";
import Footer from "./Footer";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Card />
        <Footer />
      </div>
    );
  }
}

export default App;
