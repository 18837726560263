import React, { Component, RefObject } from "react";
import "./Card.css";
import styled from "styled-components";

const Phone = styled.div`
  font-size: 21px;
  font-weight: 600;
`;

const Email = styled.div`
  font-size: 16px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const CompanyDetails = styled.ul`
  position: absolute;
  bottom: 0;
  list-style-type: none;
  text-align: left;
  padding: 34px 24px;
  font-size: 12px;

  @media (max-width: 600px) {
    padding: 0 0 34px 8px;
    font-size: 11px;
  }
`;

const CVR = styled.li`
  margin-bottom: 6px;
`;

class Card extends Component {
  cardElem: RefObject<HTMLDivElement>;
  state: State;

  constructor(props: any) {
    super(props);
    this.cardElem = React.createRef();
    this.state = new State();
  }

  openClose(isOpened: Boolean) {
    this.setState({ isOpened: isOpened });
  }

  render() {
    return (
      <div className={this.state.isOpened ? "flip-card flipped" : "flip-card"}>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <div className="open-btn" onClick={() => this.openClose(true)}>
              OPEN HERE
            </div>
          </div>
          <div className="flip-card-back Card">
            <div className="left">
              <img
                src={require("../img/logo.svg")}
                className="logo"
                alt="logo"
              />
              <div className="company-name">Enthusiast Studio</div>
              <CompanyDetails>
                <CVR>CVR: 43537970</CVR>
                <li>Strandboulevarden 97, st.tv</li>
                <li>Copenhagen, Denmark</li>
              </CompanyDetails>
            </div>
            <div className="card-side right">
              <span className="close-btn" onClick={() => this.openClose(false)}>
                x
              </span>
              <div className="top-fields">
                <h1 className="name">Jekabs Karklins</h1>
                <h2 className="title">Full stack engineer</h2>
              </div>
              <ul className="bottom-fields">
                <Phone>+45 42713777</Phone>
                <Email>enthusiast.studio@gmail.com</Email>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class State {
  isOpened: Boolean = false;
  openText: String = "";
}

export default Card;
